<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header">
                
                <div class="content__header-left">
                    <router-link to="/reports/edx-analysis" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require('@/assets/img/Arrow-prev.svg')" />
                    </router-link>
                    <h1 style="margin-left:20px" class="heading-page-h1">{{report.name||'EDX Analysis'}}</h1>
                </div>
            </div>

            <TableAdvanced v-model="sortCol" :rows="rawSource.length>0?[...rawSource,totalRow]:[]" :columns="columns" :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs">
                <template v-slot:filters>
                    <div class="filter-options__items flex-auto">
                        <div class="filter-options__search">
                            <input type="search" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
                        </div>
                    </div>
                </template>

                <template v-slot:row="slotData">
                    <td>
                        <label class="module__check" v-if="slotData.r.id !== 'TOTAL'">
                            <input type="checkbox" name="product" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event, slotData.r.id)" />
                            <span class="check"></span>
                            <span class="text">
                                <span class="ml-10">{{ slotData.r.name }}</span>
                            </span>
                        </label>
                        <label class="module__check" v-else>
                            <span class="text" style="padding-left: 22px; cursor: pointer">
                                <span class="ml-10">{{ slotData.r.name }}</span>
                            </span>
                        </label>
                    </td>
                    <td>
                        {{ slotData.r.column1 }}
                    </td>
                    <td>
                        {{ slotData.r.column2 }}
                    </td>
                    <td>
                        {{ slotData.r.column3 }}
                    </td>
                    <td>
                        {{ slotData.r.column4 }}
                    </td>
                    <td>
                        {{ slotData.r.column5 }}
                    </td>
                    <td>
                        {{ slotData.r.column6 }}
                    </td>
                    <td>
                        {{ slotData.r.column7 }}
                    </td>
                </template>
            </TableAdvanced>
            <TableMessages
                :rows="rows"
                :filters="rowsFilters"
                title="No Report Data"               
                searchTitle="No Data Corresponding To The Criteria"
            ></TableMessages>
            <div class="items-group cards border-top" style="margin-bottom: 25px" v-if="report.product">
                <div class="cards__col-6">
                    <div class="el-form mt-15 flex-auto">
                        <h3>{{ $translate('report-name') }}</h3> 
                        <p style="margin-top: 15px">{{ report.name }}</p>
                    </div>
                </div>
                <div class="cards__col-6">
                    <div class="el-form mt-15 flex-auto">
                        <h3>{{ $translate('product') }}</h3> 
                        <p style="margin-top: 15px">{{ products.find(item => item.id === report.product).name }}</p>
                    </div>
                </div>
            </div>
            <div class="items-group cards mt-15">
                <div class="cards__col-6">     
                    <h3>{{ $translate('images') }}</h3>
                    <div v-if="report.images&&report.images.length==0" style="margin-top: 15px">{{ $translate('no-images') }}</div>

                    <div class="gallery" v-else>
                        <div class="gallery__item" v-for="image in report.images" :key="image.url">
                            <img :src="image.url" alt="" />
                        </div>
                    </div>
                </div>
                <div class="cards__col-6">
                    <h3>{{ $translate('comments') }}</h3>
                    <div v-if="report.comments&&report.comments.length==0" style="margin-top: 15px">{{ $translate('no-comments') }}</div>
                    <p v-else class="comment" v-for="(comment,index) in report.comments" :key="index">
                        {{ comment }}
                    </p>
                </div>
            </div>
      
        </main>

    </div>
</template>

<script>
import { getCustomReport } from "@/graphql/queries";
import { API,Storage } from "aws-amplify";

import Popups from "@/components/popups";
import Base from "@/components/base";
import Buttons from "@/components/buttons";
import SimplePagination from "./SimplePagination";
import { mixTables } from "@/mixins";

export default {
    components: {
        ...Popups,
        ...Base,
        ...Buttons,
        SimplePagination
    },
    mixins: [mixTables],
    props:['id'],
    data() {
        return {
            source: "edxReports",
            columns: [
                { name: "Name", filter: "name" },
                { name: "VET1 Map Sum Spectrum", filter: "column1" },
                { name: "Line Type", filter: "column2" },
                { name: "Apparent Concentration", filter: "column3" },
                { name: "k Ratio", filter: "column4" },
                { name: "Wt%", filter: "column5" },
                { name: "Wt% Sigma", filter: "column6" },
                { name: "Standard Label", filter: "column7" },
            ],
            report:{},
        };
    },
    async created() {
        const report = await API.graphql({
            query: getCustomReport,
            variables: {
                id: this.id,
            },
        }).then((res) => res.data.getCustomReport);
        let imagesRequests=report.images.map(async key=>{
            return {url:await Storage.get(key)}
        })
        await Promise.all(imagesRequests).then(res=>report.images=res)
        this.report = report
    },
    computed: {
        sourceData() {
            if(!this.report.tableData){
                return []
            }
            return this.report?.tableData.map(r=>{
                return {
                    name:r[0],
                    column1:r[1],
                    column2:r[2],
                    column3:this.toInt(r[3]),
                    column4:this.toInt(r[4]),
                    column5:this.toInt(r[5]),
                    column6:this.toInt(r[6]),
                    column7:r[7]
                }
            })
         
        },
        totalRow(){
            let data=this.rawSource
            if(data.length==0){
                console.log("data 0");
                return {}
            }
            let numberString = [];
            data.forEach((d) => {
                let dataValues = Object.entries(d);
                dataValues.forEach(([dataKey, dataValue]) => {
                    if (typeof dataValue == "number" && !numberString.includes(dataKey)) {
                        numberString.push(dataKey);
                    }
                });
            });
            let row=[]
            Object.keys(data[0]).forEach((key) => {
                row[key] = numberString.includes(key) ? data.reduce((acc, current) => acc + (current[key] ? current[key] : 0), 0) : "-";
            });
            row.name = "TOTAL";
            row.id = "TOTAL";
            return row;
        },
        products(){
            let products = this.$store.state.products || [];
            return products.map(p => ({ id: p.id, name: p.title }));
        },
    },
    methods: {
        toInt(str){
            if(Number.isInteger(Number(str)) ){
                return +str
            }
            else{
                return str
            }
        }
    },
};
</script>

<style scoped>
.cards__col-6 {
    padding-left: 25px;
}
.comment{
    padding-top: 25px;
}
</style>
